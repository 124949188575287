export const Action = {
    DURACIONES: "DURACIONES",
    DURACIONES_ACT: "DURACIONES_ACT",
    DURACIONES_NEW: "DURACIONES_NEW",
    DURACIONES_DELETE: "DURACIONES_DELETE",
    DURACIONES_TAG: "DURACIONES_TAG",
};
export const Mutation = {
    DURACIONES: "DURACIONES",
    DURACIONES_ACT: "DURACIONES_ACT",
    DURACIONES_NEW: "DURACIONES_NEW",
    DURACIONES_DELETE: "DURACIONES_DELETE",
    DURACIONES_TAG: "DURACIONES_TAG",
};