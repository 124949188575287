export const Action = {
    GRUPOS_CAPACITACIONES: "GRUPOS_CAPACITACIONES",
    GRUPOS_TRABAJADORES_ACT: "GRUPOS_TRABAJADORES_ACT",
    GRUPOS_TRABAJADORES_NEW: "GRUPOS_TRABAJADORES_NEW",
    GRUPOS_TRABAJADORES_DELETE: "GRUPOS_TRABAJADORES_DELETE",
    GRUPOS_UPLOAD_FILE: "GRUPOS_UPLOAD_FILE",
    GRUPOS_DOWNLOAD_TEMPLATE: "GRUPOS_DOWNLOAD_TEMPLATE",
};
export const Mutation = {
    GRUPOS_CAPACITACIONES: "GRUPOS_CAPACITACIONES",
    GRUPOS_TRABAJADORES_ACT: "GRUPOS_TRABAJADORES_ACT",
    GRUPOS_TRABAJADORES_NEW: "GRUPOS_TRABAJADORES_NEW",
    GRUPOS_TRABAJADORES_DELETE: "GRUPOS_TRABAJADORES_DELETE",
};