<template>
  <div>
    <v-dialog
      v-model="isDialogActive"
      @keydown.esc="isDialogActive = false"
      transition="dialog-bottom-transition"
      max-width="600px"
      persistent
      overlay-color="primary lighten-4"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-btn
            color="primary"
            icon
            text
            @click="closeDialog = !closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="primary--text">
            <span v-if="!isNewItemSt">
              {{ $t('edit') }}
            </span>
            <span v-if="isNewItemSt">
              {{ $t('create-new') }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="primary"
              text
              :loading="loading"
              :disabled="loading" 
              @click="submit"
            >
              {{ $t( 'save' ) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
          justify="center"
        >
          <template>
            <ValidationObserver ref="ubObs">
              <v-container
                id="capacitador"
              >
                <v-form>
                  <ValidationProvider name="capacitadores.title" rules="max:80" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.titulo"
                      :label="$t('fields.capacitadores.title')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="capacitadores.name" rules="required|max:80" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.nombres"
                      :label="$t('fields.capacitadores.name')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="capacitadores.last-name" rules="required|max:80" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.apellidos"
                      :label="$t('fields.capacitadores.last-name')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="capacitadores.stps-registry" rules="required|max:80" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.registro_stps"
                      :label="$t('fields.capacitadores.stps-registry')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                </v-form>
              </v-container>
            </ValidationObserver>
          </template>
        </v-list>
      </v-card>
    </v-dialog>
    <error-alert :dialog="errorDialog" />
  </div>
</template>


<script>
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { Action as capacitadoresAction } from '../../store/cruds/capacitadores/capacitadores/types'
import { Action as editDialogAction } from '../../store/cruds/capacitadores/edit_dialog/types'
import { Action as editedItemAction } from '../../store/cruds/capacitadores/edited_item/types'
import responses from "../../util/responses";
import ErrorAlert from '../ErrorAlert.vue'
const reponseUtil = new responses()

setInteractionMode('eager')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ErrorAlert
  },
  data () {
    return {
      errorDialog: false,
      loader: null,
      loading: false,
      closeDialog: false,
      editedItem: {
        id: null,
        nombres: '',
        apellidos: '',
        titulo: '',
      },
      errors: null,
      fieldsRelations: {
        "titulo": "capacitadores.title",
        "nombres": "capacitadores.name",
        "apellidos": "capacitadores.last-name",
        "registro_stps": "capacitadores.stps-registry",
      }
    }
  },
  computed: {
    editedItemSt : function(){ return this.$store.getters.getCapacitadoresEditedItem ?? [] },
    isNewItemSt : function(){ return this.$store.getters.isCapacitadoresNewItem ?? [] },
    isDialogActive : {
      get: function(){ return this.$store.getters.isCapacitadoresEditDialogActive },
      set: function(val) {
        this.$store.dispatch(`${[editDialogAction.CAPACITADORES_EDIT_DIALOG]}`, val)
      }
    }
  },
  watch: {
    editedItemSt (editedItem) {
      this.editedItem = Object.assign({}, editedItem)
    },
    loader () { 
      const l = this.loader
      this[l] = !this[l]
    },
    closeDialog: function () {
      this.$store.dispatch(`${[editDialogAction.CAPACITADORES_EDIT_DIALOG]}`, false)
      this.$store.dispatch(`${[editedItemAction.CAPACITADORES_IS_NEW_ITEM]}`, false)
      this.$refs.ubObs.reset()
    },
  },
  methods: {
    textFieldEnter(e){
      e.preventDefault();
      this.submit();
    },
    isNewItem(){
        if(this.isNewItemSt){
          return capacitadoresAction.CAPACITADORES_NEW
        }
        return capacitadoresAction.CAPACITADORES_ACT
    },
    submit() {
      this.$refs.ubObs.validate().then(res => {
        if(res){
          this.loader = 'loading'
          let data = {
            id: this.editedItem.id,
            nombres: this.editedItem.nombres,
            apellidos: this.editedItem.apellidos,
            titulo: this.editedItem.titulo,
            registro_stps: this.editedItem.registro_stps
          }
          var action = this.isNewItem()
          this.$store.dispatch(`${[action]}`, data)
          .then((response, request) => {
            this.loader = null
            this.loading = false
            this.closeDialog = !this.closeDialog
            })
          .catch(err => {
            if(err && err.response && err.response.data && err.response.data.errors) {
              this.$refs.ubObs.setErrors(reponseUtil.fixResponseErrors(err.response.data.errors, this.fieldsRelations));
            } else {
              this.errorDialog = true
              this.closeDialog = !this.closeDialog
            }
            this.loader = null
            this.loading = false
          })
        }
      })
    }
  }
}
</script>