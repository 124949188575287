<template>
  <div>
    <bread-crumbs/>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="8"
          offset-md="2"
        >
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="duraciones"
              :items-per-page="5"
              :loading="loadingVar"
              class="elevation-1"
              :footer-props="{
                itemsPerPageText: $t('data-table.footer.rows-per-page'),
                itemsPerPageAllText: $t('data-table.footer.all'),
                pageText: $t('data-table.footer.page-text')
                }"
            >

              <template v-slot:top>
              <v-toolbar dense flat color="transparent">
                <v-toolbar-title>Duraciones</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  elevation="2"  
                  class="mb-2"
                  @click="newItem()"
                >
                  {{ $t('new-item') }}
                </v-btn>
                <template>
                  <editar-duracion />
                </template>
                <confirm-dialog ref="deleteDialogDuraciones" />
              </v-toolbar>
              </template>
              <template  v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                    v-model="item.action"
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                    @click="deleteItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    >
                      {{ icons.mdiTrashCan }}
                    </v-icon>
                  </template>
                  <span>{{ $t('remove') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Action as duracionesAction } from '../../store/cruds/duraciones/duraciones/types'
import { Action as editDialogAction } from '../../store/cruds/duraciones/edit_dialog/types'
import { Action as editedItemAction } from '../../store/cruds/duraciones/edited_item/types'
import { Action as progBarAction } from '../../store/prog_bar/types'
import {
    mdiPencil,
    mdiTrashCan
  } from '@mdi/js'
import EditarDuracion from './EditarDuracion.vue'
import BreadCrumbs from './BreadCrumbs'
import ConfirmDialog from '../ConfirmDialog.vue'

export default {
  components: {
    EditarDuracion,
    BreadCrumbs,
    ConfirmDialog
  },
  data () {
    return {
      search: '',
      icons: {
        mdiPencil,
        mdiTrashCan
      },
      marcador: {},
      loadingVar: true,
    }
  },
  computed: {
    duraciones : function(){ 
      return this.$store.getters.getDuraciones ?? [] },
    headers() {
       return [ {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: this.$t('fields.duraciones.name'), value: 'nombre' },
        { text: this.$t('fields.duraciones.hours'), value: 'horas' },
        { text: this.$t('actions'), value: 'actions', sortable: false},
      ]
    }
  },
  created: function(){
    this.$store.dispatch(`${[duracionesAction.DURACIONES]}`)
    .then(()=> {
      this.loadingVar = false
    })
  },
  methods: {
    editItem (item) {
      this.$store.dispatch(`${[editedItemAction.DURACIONES_IS_NEW_ITEM]}`, false)
      this.$store.dispatch(`${[editedItemAction.DURACIONES_EDITED_ITEM]}`, item)
      this.$store.dispatch(`${[editDialogAction.DURACIONES_EDIT_DIALOG]}`, true)
    },
    newItem () {
      var defaultItem = {
        id: null,
        nombre: '',
      };
      this.$store.dispatch(`${[editedItemAction.DURACIONES_EDITED_ITEM]}`, defaultItem)
      this.$store.dispatch(`${[editedItemAction.DURACIONES_IS_NEW_ITEM]}`, true)
      this.$store.dispatch(`${[editDialogAction.DURACIONES_EDIT_DIALOG]}`, true)
    },
    deleteItem (item) {
      this.$refs.deleteDialogDuraciones.open( this.$tc('data-table.confirm-delete', 1), this.$t('remove'), { } )
      .then((confirm) => {
        if(confirm) {
          this.deleteItemConfirm(item)
        }
      })
    },
    deleteItemConfirm (item) {
      this.$store.dispatch(`${[progBarAction.LOADING]}`, true)
      this.$store.dispatch(`${[duracionesAction.DURACIONES_DELETE]}`, item)
      .then(() => {
        this.$store.dispatch(`${[progBarAction.LOADING]}`, false)
      })
    },
    defaultItem () {
      return Object.assign({}, this.defaultObItem) 
    }
  }
}
</script>