<template>
  <div>
    <v-dialog
      v-model="isDialogActive"
      @keydown.esc="isDialogActive = false"
      transition="dialog-bottom-transition"
      max-width="600px"
      persistent
      overlay-color="primary lighten-4"
      scrollable
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-btn
            color="primary"
            text
            icon
            @click="closeDialog = !closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="primary--text">
            <span v-if="!isNewItemSt">
              {{ $t('edit') }}
            </span>
            <span v-if="isNewItemSt">
              {{ $t('create-new') }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="primary"
              text
              :loading="loading"
              :disabled="loading" 
              @click="submitForm"
            >
              {{ $t( 'save' ) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text style="height: 400px;">
          <v-container>
            <v-avatar size="150" tile v-if="showLogo">
              <div class="remove-logo-img">
                <v-btn v-if="editedItem.id_logotipo" icon @click="dialogDeleteLogo = true">
                  <v-icon> mdi-close-circle-outline </v-icon>
                </v-btn>
              </div>
              <img :src="logotipo" />
            </v-avatar>
            <v-avatar v-if="!showLogo" size="150" color="darken2" tile><span class="white--text">{{ $t('logo') }}</span></v-avatar>
          </v-container>
          <template>
            <ValidationObserver ref="ubObs">
              <v-container
                id="cliente-empresa"
              >
                <v-form>
                  <ValidationProvider name="clientes-empresas.logo" rules="image|size:2000" v-slot="{ errors }">
                    <v-file-input
                      v-model="logoFileInput" 
                      :label="$t('fields.clientes-empresas.logo-click')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                      @change="selectedFile"
                    >
                    </v-file-input>
                  </ValidationProvider>
                  <ValidationProvider name="clientes-empresas.name" rules="required|max:80" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.nombre"
                      :label="$t('fields.clientes-empresas.name')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="clientes-empresas.rfc" rules="max:20" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.rfc"
                      :label="$t('fields.clientes-empresas.rfc')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="clientes-empresas.imss-registry" rules="max:80" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.registro_imss"
                      :label="$t('fields.clientes-empresas.imss-registry')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                    <ValidationProvider name="clientes-empresas.street" rules="max:80" v-slot="{ errors }">
                      <v-text-field
                        v-model="editedItem.calle"
                        :label="$t('fields.clientes-empresas.street')"
                        :error-messages="errors"
                        :error-count=4
                        @keydown.enter="textFieldEnter"
                      >
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="clientes-empresas.number" rules="max:80" v-slot="{ errors }">
                      <v-text-field
                        v-model="editedItem.numero"
                        :label="$t('fields.clientes-empresas.number')"
                        :error-messages="errors"
                        :error-count=4
                        @keydown.enter="textFieldEnter"
                      >
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="clientes-empresas.zip-code" rules="max:80" v-slot="{ errors }">
                      <v-text-field
                        v-model="editedItem.codigo_postal"
                        :label="$t('fields.clientes-empresas.zip-code')"
                        :error-messages="errors"
                        :error-count=4
                        @keydown.enter="textFieldEnter"
                      >
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="clientes-empresas.city" rules="max:80" v-slot="{ errors }">
                      <v-text-field
                        v-model="editedItem.ciudad"
                        :label="$t('fields.clientes-empresas.city')"
                        :error-messages="errors"
                        :error-count=4
                        @keydown.enter="textFieldEnter"
                      >
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="clientes-empresas.state" rules="max:80" v-slot="{ errors }">
                      <v-text-field
                        v-model="editedItem.estado"
                        :label="$t('fields.clientes-empresas.state')"
                        :error-messages="errors"
                        :error-count=4
                        @keydown.enter="textFieldEnter"
                      >
                      </v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="clientes-empresas.country" rules="max:80" v-slot="{ errors }">
                      <v-text-field
                        v-model="editedItem.pais"
                        :label="$t('fields.clientes-empresas.country')"
                        :error-messages="errors"
                        :error-count=4
                        @keydown.enter="textFieldEnter"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  <ValidationProvider name="clientes-empresas.contact" rules="max:180" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.contacto"
                      :label="$t('fields.clientes-empresas.contact')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="clientes-empresas.activities" rules="" v-slot="{ errors }">
                    <v-select
                      v-model="editedItem.id_cat_empresa_giro"
                      :items="empresas_giros"
                      :label="$t('fields.clientes-empresas.activities')"
                      :error-messages="errors"
                      item-text="nombre"
                      item-value="id"
                      chips
                      small-chips
                      deletable-chips
                    />
                  </ValidationProvider>
                </v-form>
              </v-container>
            </ValidationObserver>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <error-alert :dialog="errorDialog" />
    <v-dialog v-model="dialogDeleteLogo" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{ $t('confirm-delete-logo') }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteLogoConfirm">OK</v-btn>
          <v-btn color="blue darken-1" text @click="dialogDeleteLogo = false">{{ $t('cancel') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { Action as clientesEmpresasAction } from '../../store/cruds/clientes_empresas/clientes_empresas/types'
import { Action as editDialogAction } from '../../store/cruds/clientes_empresas/edit_dialog/types'
import { Action as editedItemAction } from '../../store/cruds/clientes_empresas/edited_item/types'
import responses from "../../util/responses";
import ErrorAlert from '../ErrorAlert.vue'
const reponseUtil = new responses()
setInteractionMode('eager')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ErrorAlert
  },
  data () {
    return {
      showLogo: false,
      componentKey: 0,
      logoFileInput: null,
      errorDialog: false,
      dialogDeleteLogo: false,
      loading: false,
      closeDialog: false,
      editedItem: {
        id: null,
        nombre: '',
        rfc: '',
        registro_imss: '',
        contacto: '',
        calle: '',
        numero: '',
        codigo_postal: '',
        ciudad: '',
        estado: '',
        pais: '',
      },
      errors: null,
      fieldsRelations: {
        "nombre": "clientes-empresas.name",
        "rfc": "clientes-empresas.rfc",
        "registro_imss": "clientes-empresas.imss-registry",
        "contacto": "clientes-empresas.contact",
        "logotipo": "clientes-empresas.logotipo",
        "calle": "clientes-empresas.street",
        "numero": "clientes-empresas.number",
        "codigo_postal": "clientes-empresas.zip-code",
        "ciudad": "clientes-empresas.city",
        "estado": "clientes-empresas.state",
        "pais": "clientes-empresas.country",
      },
    }
  },
  computed: {
    logotipo: function() { return this.$store.getters.getClienteEmpresaLogotipo(this.editedItem.id) ?? [] },
    empresas_giros : function(){ return this.$store.getters.getEmpresasGiros ?? [] },
    editedItemSt : function(){ return this.$store.getters.getClientesEmpresasEditedItem ?? [] },
    isNewItemSt : function(){ return this.$store.getters.isClientesEmpresasNewItem ?? [] },
    isDialogActive : {
      get: function(){ return this.$store.getters.isClientesEmpresasEditDialogActive },
      set: function(val) {
        this.$store.dispatch(`${[editDialogAction.CLIENTES_EMPRESAS_EDIT_DIALOG]}`, val)
      }
    }
  },
  watch: {
    isDialogActive(newVal, oldVal) {
      if(newVal && !oldVal){
        var item = this.$store.getters.getClientesEmpresasEditedItem
        this.editedItem = Object.assign({}, item)
        this.showLogo = !!this.editedItem.id_logotipo
      }
    },
    editedItemSt (editedItem) {
      this.editedItem = Object.assign({}, editedItem)
    },
    closeDialog: function () {
      this.$store.dispatch(`${[editDialogAction.CLIENTES_EMPRESAS_EDIT_DIALOG]}`, false)
      this.$store.dispatch(`${[editedItemAction.CLIENTES_EMPRESAS_IS_NEW_ITEM]}`, false)
      this.$refs.ubObs.reset()
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    selectedFile(file) {
      // console.log('this.logoFileInput', this.logoFileInput)
    },
    textFieldEnter(e){
      e.preventDefault();
      this.submitForm();
    },
    isNewItem(){
        if(this.isNewItemSt){
          return clientesEmpresasAction.CLIENTES_EMPRESAS_NEW
        }
        return clientesEmpresasAction.CLIENTES_EMPRESAS_ACT
    },
    async deleteLogoConfirm(){
      this.dialogDeleteLogo = false
      this.loading = true
      var error = null
      try {
        await this.$store.dispatch(`${[clientesEmpresasAction.CLIENTES_EMPRESAS_DELETE_LOGO]}`, {id: this.editedItem.id})
        await this.$store.dispatch(`${[clientesEmpresasAction.CLIENTES_EMPRESAS_RELOAD]}`, {id: this.editedItem.id})
      } catch (err) {
        if(err && err.response && err.response.data && err.response.data.errors) {
          await this.$refs.ubObs.setErrors(reponseUtil.fixResponseErrors(err.response.data.errors, this.fieldsRelations));
        }
        error = err
      }
      if(!error){
        this.showLogo = false
      } else {
        this.errorDialog = true
        this.logoFileInput = null
        this.closeDialog = !this.closeDialog
      }
      this.loading = false
    },
    async submitForm(){
      let validation = await this.$refs.ubObs.validate();
      if(validation){
        this.loading = true
        let data = {
          id: this.editedItem.id,
          nombre: this.editedItem.nombre,
          rfc: this.editedItem.rfc,
          registro_imss: this.editedItem.registro_imss,
          contacto: this.editedItem.contacto,
          id_cat_empresa_giro: this.editedItem.id_cat_empresa_giro,
          calle: this.editedItem.calle,
          numero: this.editedItem.numero,
          codigo_postal: this.editedItem.codigo_postal,
          ciudad: this.editedItem.ciudad,
          estado: this.editedItem.estado,
          pais: this.editedItem.pais,
        }
        var action = this.isNewItem()
        let capacitacion_response = null
        let error = null

        try {
          capacitacion_response = await this.$store.dispatch(`${[action]}`, data)
        } catch (err) {
          if(err && err.response && err.response.data && err.response.data.errors) {
            await this.$refs.ubObs.setErrors(reponseUtil.fixResponseErrors(err.response.data.errors, this.fieldsRelations));
          }
          error = err.error
        }
        
        if(!error && this.logoFileInput){
          try {
            var logoData = {
              id: capacitacion_response.data.id,
              logotipo: this.logoFileInput
            }
            await this.$store.dispatch(`${[clientesEmpresasAction.CLIENTES_EMPRESAS_UPDATE_LOGO]}`, logoData)
            await this.$store.dispatch(`${[clientesEmpresasAction.CLIENTES_EMPRESAS_RELOAD]}`, {id:capacitacion_response.data.id})
          } catch (err) {
            error = err.error
          }
        }

        if(!error && this.logoFileInput){
          try {
            var result = await this.$store.dispatch(`${[clientesEmpresasAction.CLIENTES_EMPRESAS_DOWNLOAD_LOGO]}`, {id:capacitacion_response.data.id})
          } catch (err) {
            error = err.error
          }
        }
        if(error) {
          this.errorDialog = true
        }
        this.logoFileInput = null
        this.closeDialog = !this.closeDialog
        this.loading = false
      }
    }
  }
}
</script>

<style>
.remove-logo-img{
  position:absolute;
  top:0px;left:0px;
}
</style>