export const Action = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    REGISTER: "REGISTER",
    INIT: "INIT",
};
export const Mutation = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    AUTH_REQUEST: "AUTH_REQUEST",
    AUTH_ERROR: "AUTH_ERROR",
    INIT: "INIT",
};