<template>
  <div>
    <v-dialog
      v-model="isDialogActive"
      @keydown.esc="isDialogActive = false"
      transition="dialog-bottom-transition"
      max-width="600px"
      persistent
      overlay-color="primary lighten-4"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-btn
            color="primary"
            text
            icon
            @click="closeDialog = !closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="primary--text">
            <span v-if="!isNewItemSt">
              {{ $t('edit') }}
            </span>
            <span v-if="isNewItemSt">
              {{ $t('create-new') }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              text
              color="primary"
              :loading="loading"
              :disabled="loading" 
              @click="submit"
            >
              {{ $t( 'save' ) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
          justify="center"
        >
          <template>
            <ValidationObserver ref="ubObs">
              <v-container
                id="duracion"
              >
                <v-form>
                  <ValidationProvider name="duraciones.hours" :rules="{ regex: /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, required: true }" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.horas"
                      :label="$t('fields.duraciones.labels.hours')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="duraciones.name" rules="required|max:80" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.nombre"
                      :label="$t('fields.duraciones.labels.name')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                </v-form>
              </v-container>
            </ValidationObserver>
          </template>
        </v-list>
      </v-card>
    </v-dialog>
    <error-alert :dialog="errorDialog" />
  </div>
</template>


<script>
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { Action as duracionesAction } from '../../store/cruds/duraciones/duraciones/types'
import { Action as editDialogAction } from '../../store/cruds/duraciones/edit_dialog/types'
import { Action as editedItemAction } from '../../store/cruds/duraciones/edited_item/types'
import responses from "../../util/responses";
import ErrorAlert from '../ErrorAlert.vue'
const reponseUtil = new responses()

setInteractionMode('eager')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ErrorAlert
  },
  data () {
    return {
      errorDialog: false,
      loader: null,
      loading: false,
      closeDialog: false,
      editedItem: {
        id: null,
        nombre: '',
      },
      errors: null,
      fieldsRelations: {
        "nombre": "duraciones.name",
        "horas": "duraciones.hours",
      }
    }
  },
  computed: {
    editedItemSt : function(){ return this.$store.getters.getDuracionesEditedItem ?? [] },
    isNewItemSt : function(){ return this.$store.getters.isDuracionesNewItem ?? [] },
    isDialogActive : {
      get: function(){ return this.$store.getters.isDuracionesEditDialogActive },
      set: function(val) {
        this.$store.dispatch(`${[editDialogAction.DURACIONES_EDIT_DIALOG]}`, val)
      }
    }
  },
  watch: {
    editedItemSt (editedItem) {
      this.editedItem = Object.assign({}, editedItem)
    },
    loader () { 
      const l = this.loader
      this[l] = !this[l]
    },
    closeDialog: function () {
      this.$store.dispatch(`${[editDialogAction.DURACIONES_EDIT_DIALOG]}`, false)
      this.$store.dispatch(`${[editedItemAction.DURACIONES_IS_NEW_ITEM]}`, false)
      this.$refs.ubObs.reset()
    },
  },
  methods: {
    textFieldEnter(e){
      e.preventDefault();
      this.submit();
    },
    isNewItem(){
        if(this.isNewItemSt){
          return duracionesAction.DURACIONES_NEW
        }
        return duracionesAction.DURACIONES_ACT
    },
    submit() {
      this.$refs.ubObs.validate().then(res => {
        if(res){
          this.loader = 'loading'
          let data = {
            id: this.editedItem.id,
            nombre: this.editedItem.nombre,
            horas: this.editedItem.horas,
          }
          var action = this.isNewItem()
          this.$store.dispatch(`${[action]}`, data)
          .then((response, request) => {
            this.loader = null
            this.loading = false
            this.closeDialog = !this.closeDialog
            })
          .catch(err => {
            if(err && err.response && err.response.data && err.response.data.errors) {
              this.$refs.ubObs.setErrors(reponseUtil.fixResponseErrors(err.response.data.errors, this.fieldsRelations));
            } else {
              this.errorDialog = true
              this.closeDialog = !this.closeDialog
            }
            this.loader = null
            this.loading = false
          })
        }
      })
    }
  }
}
</script>