<template>
  <v-row justify="center">
    <v-dialog
      v-model="isDialogActive"
      @keydown.esc="isDialogActive = false"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      overlay-color="primary lighten-4"
    >
      <v-card>
        <v-toolbar elevation="0"
        >
          <v-btn
            icon
            text
            color="primary"
            @click="closeDialog = !closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="primary--text">
            <span v-if="!isNewItemSt">
              {{ $t('edit') }}
            </span>
            <span v-if="isNewItemSt">
              {{ $t('create-new') }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              text
              color="primary"
              :loading="loading"
              :disabled="loading" 
              @click="submit"
            >
              {{ $t( 'save' ) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
          justify="center"
        >
          <template>
            <ValidationObserver ref="ubObs">
              <v-container
                id="capacitador"
              >
                <v-form>
                  <ValidationProvider name="trabajadores.name" rules="required|max:100" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.nombres"
                      :label="$t('fields.trabajadores.name')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.last-name" rules="required|max:100" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.apellidos"
                      :label="$t('fields.trabajadores.last-name')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.course" rules="" v-slot="{ errors }">
                    <v-combobox
                      v-model="editedItem.curso"
                      :items="cursos"
                      :search-input.sync="search"
                      hide-selected
                      persistent-hint
                      small-chips
                      item-text="nombre"
                      item-value="id" 
                      :error-messages="errors"
                      :label="$t('fields.trabajadores.course')"
                      deletable-chips
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span v-html="$t('no-results-matching-error', {search: search})"></span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.worker-number.full" rules="max:80" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.numero_colaborador"
                      :label="$t('fields.trabajadores.worker-number.full')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.rfc" rules="max:20" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.rfc"
                      :label="$t('fields.trabajadores.rfc')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.right-guesses" rules="integer|max:100" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.aciertos"
                      :label="$t('fields.trabajadores.right-guesses')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.questions" rules="integer|max:100" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.preguntas"
                      :label="$t('fields.trabajadores.questions')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.observations.full" rules="max:100" v-slot="{ errors }">
                    <v-text-field
                      v-model="editedItem.observaciones"
                      :label="$t('fields.trabajadores.observations.full')"
                      :error-messages="errors"
                      :error-count=4
                      @keydown.enter="textFieldEnter"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.department" rules="" v-slot="{ errors }">
                    <v-combobox
                      v-model="editedItem.departamento"
                      :items="departamentos"
                      :search-input.sync="search"
                      hide-selected
                      persistent-hint
                      small-chips
                      item-text="nombre"
                      item-value="id" 
                      :error-messages="errors"
                      :label="$t('fields.trabajadores.department')"
                      deletable-chips
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span v-html="$t('no-results-matching-error', {search: search})"></span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>

                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.position" rules="" v-slot="{ errors }">
                    <v-combobox
                      v-model="editedItem.puesto"
                      :items="puestos"
                      :search-input.sync="search"
                      hide-selected
                      persistent-hint
                      small-chips
                      item-text="nombre"
                      item-value="id" 
                      :error-messages="errors"
                      :label="$t('fields.trabajadores.position')"
                      deletable-chips
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span v-html="$t('no-results-matching-error', {search: search})"></span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </ValidationProvider>
                  <ValidationProvider name="trabajadores.occupation-sub-area" rules="" v-slot="{ errors }">
                    <v-select
                      v-model="editedItem.id_cat_sub_area_ocupacion"
                      :items="sub_areas_ocupaciones"
                      :label="$t('fields.trabajadores.occupation-sub-area')"
                      :error-messages="errors"
                      item-text="nombre"
                      item-value="id"
                      clearable
                      small-chips
                      deletable-chips
                    />
                  </ValidationProvider>
                </v-form>
              </v-container>
            </ValidationObserver>
          </template>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeDialog = !closeDialog"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="loading"
            :disabled="loading"
            @click="submit"
          >
            {{ $t( 'save' ) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-alert :dialog="errorDialog" />
  </v-row>
</template>


<script>
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { Action as trabajadoresAction } from '../../../store/cruds/capacitaciones/grupos_capacitaciones/grupos_capacitaciones/types'
import { Action as editDialogAction } from '../../../store/cruds/capacitaciones/grupos_capacitaciones/trabajadores/edit_dialog/types'
import { Action as editedItemAction } from '../../../store/cruds/capacitaciones/grupos_capacitaciones/trabajadores/edited_item/types'
import ErrorAlert from '../../ErrorAlert.vue'
import responses from "../../../util/responses";
const reponseUtil = new responses()

setInteractionMode('eager')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ErrorAlert
  },
  data () {
    return {
      loader: null,
      errorDialog: false,
      loading: false,
      closeDialog: false,
      search: null,
      editedItem: {
        id: null,
        nombres: '',
        apellidos: '',
        titulo: '',
      },
      errors: null,
      fieldsRelations: {
        "nombres": "trabajadores.name",
        "apellidos": "trabajadores.last-name",
        "numero_colaborador": "trabajadores.worker-number.full",
        "rfc": "trabajadores.rfc",
        "aciertos": "trabajadores.right-guesses",
        "preguntas": "trabajadores.questions",
        "observaciones": "trabajadores.observations.full",
        "curso": "trabajadores.course",
        "departamento": "trabajadores.department",
        "puesto": "trabajadores.position",
        "id_cat_sub_area_ocupacion": "trabajadores.occupation-sub-area",
      }
    }
  },
  computed: {
    cursos : function(){ return this.$store.getters.getCursos ?? [] },
    puestos : function(){ return this.$store.getters.getPuestos ?? [] },
    departamentos : function(){ return this.$store.getters.getDepartamentos ?? [] },
    sub_areas_ocupaciones : function(){ return this.$store.getters.getSubAreasOcupacionesVformatted ?? [] },
    editedItemSt : function(){ return this.$store.getters.getTrabajadoresEditedItem ?? [] },
    isNewItemSt : function(){ return this.$store.getters.isTrabajadoresNewItem ?? [] },
    isDialogActive : {
      get: function(){ return this.$store.getters.isTrabajadoresEditDialogActive },
      set: function(val) {
        this.$store.dispatch(`${[editDialogAction.CAPACITADORES_EDIT_DIALOG]}`, val)
      }
    }
  },
  watch: {
    editedItemSt (editedItem) {
      this.editedItem = Object.assign({}, editedItem)
    },
    loader () { 
      const l = this.loader
      this[l] = !this[l]
    },
    closeDialog: function () {
      this.$store.dispatch(`${[editDialogAction.TRABAJADORES_EDIT_DIALOG]}`, false)
      this.$store.dispatch(`${[editedItemAction.TRABAJADORES_IS_NEW_ITEM]}`, false)
      this.$refs.ubObs.reset()
    },
  },
  methods: {
    textFieldEnter(e){
      e.preventDefault();
      this.submit();
    },
    isNewItem(){
      if(this.isNewItemSt){
        return trabajadoresAction.GRUPOS_TRABAJADORES_NEW
      }
      return trabajadoresAction.GRUPOS_TRABAJADORES_ACT
    },
    submit() {
      this.$refs.ubObs.validate().then(res => {
        if(res){
          this.loader = 'loading'
          let data = {
            id: this.editedItem.id,
            nombres: this.editedItem.nombres,
            apellidos: this.editedItem.apellidos,
            numero_colaborador: this.editedItem.numero_colaborador,
            rfc: this.editedItem.rfc,
            id_capacitacion: parseInt(this.$route.params.id),
            aciertos: this.editedItem.aciertos,
            preguntas: this.editedItem.preguntas,
            observaciones: this.editedItem.observaciones,
            curso: this.editedItem.curso,
            departamento: this.editedItem.departamento,
            puesto: this.editedItem.puesto,
            id_cat_sub_area_ocupacion: this.editedItem.id_cat_sub_area_ocupacion,
          }
          var action = this.isNewItem()
          this.$store.dispatch(`${[action]}`, data)
          .then((response, request) => {
            this.loader = null
            this.loading = false
            this.closeDialog = !this.closeDialog
            })
          .catch(err => {
            if(err && err.response && err.response.data && err.response.data.errors) {
              this.$refs.ubObs.setErrors(reponseUtil.fixResponseErrors(err.response.data.errors, this.fieldsRelations));
            } else {
              this.errorDialog = true
              this.closeDialog = !this.closeDialog
            }
            this.loader = null
            this.loading = false
          })
        }
      })
    },
  }
}
</script>