export const Action = {
    CAPACITADORES: "CAPACITADORES",
    CAPACITADORES_ACT: "CAPACITADORES_ACT",
    CAPACITADORES_NEW: "CAPACITADORES_NEW",
    CAPACITADORES_DELETE: "CAPACITADORES_DELETE",
};
export const Mutation = {
    CAPACITADORES: "CAPACITADORES",
    CAPACITADORES_ACT: "CAPACITADORES_ACT",
    CAPACITADORES_NEW: "CAPACITADORES_NEW",
    CAPACITADORES_DELETE: "CAPACITADORES_DELETE",
};