<template>
  <hello-world />
</template>

<script>
import HelloWorld from '../components/Home.vue'

  export default {
    name: 'Home',

    components: {
      HelloWorld: HelloWorld,
    },
  }
</script>
