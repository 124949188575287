<template>
  <div>
    <v-dialog
      v-model="isDialogActive"
      @keydown.esc="isDialogActive = false"
      transition="dialog-bottom-transition"
      max-width="600px"
      persistent
      overlay-color="primary lighten-4"
    >
      <v-dialog
        v-model="cliente_empresa_dialog"
        max-width="600"
      >
        <v-card>
          <v-card-title class="headline">
            {{ cliente_empresa.nombre }}
          </v-card-title>
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-title class="text-left">
                {{ $t('fields.clientes-empresas.rfc') }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ cliente_empresa.rfc }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="text-left">
                {{ $t('fields.clientes-empresas.imss-registry') }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ cliente_empresa.registro_imss }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="text-left">
                {{ $t('fields.clientes-empresas.contact') }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ cliente_empresa.contacto }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="text-left">
                {{ $t('fields.clientes-empresas.activities') }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ cliente_empresa.giro }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              @click="cliente_empresa_dialog = false"
            >
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card>
        <v-toolbar elevation="0">
          <v-btn
            color="primary"
            text
            icon
            @click="closeDialog = !closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="primary--text">
            <span v-if="!isNewItemSt">
              {{ $t('edit') }}
            </span>
            <span v-if="isNewItemSt">
              {{ $t('create-new') }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="primary"
              text
              :loading="loading"
              :disabled="loading" 
              @click="submit"
            >
              {{ $t( 'save' ) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
          justify="center"
        >
          <template>
            <ValidationObserver ref="ubObs">
              <v-container
                id="capacitacion"
              >
                <v-form>
                  <v-menu
                    v-model="fecha_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider name="capacitaciones.date" rules="required" v-slot="{ errors }">
                        <v-text-field
                          v-model="formattedFecha"
                          :label="$t('fields.capacitaciones.date')"
                          :error-messages="errors"
                          :error-count=4
                          @keydown.enter="textFieldEnter"
                          v-bind="attrs"
                          readonly
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="editedItem.fecha"
                      @input="fecha_menu = false"
                      :locale="$i18n.locale"
                    ></v-date-picker>
                  </v-menu>
                  <ValidationProvider name="capacitaciones.trainers" rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="editedItem.capacitadores"
                      :items="capacitadores"
                      :label="$t('fields.capacitaciones.trainers')"
                      chips
                      :error-messages="errors"
                      multiple
                      :item-text="capacitadoresFormatted"
                      item-value="id"
                    />
                  </ValidationProvider>
                  <ValidationProvider name="capacitaciones.training_type" rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="editedItem.id_cat_tipo_capacitacion"
                      :items="tipos_capacitaciones"
                      :label="$t('fields.capacitaciones.training_type')"
                      :error-messages="errors"
                      item-text="nombre"
                      item-value="id"
                    />
                  </ValidationProvider>
                  <ValidationProvider name="capacitaciones.duration" rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="editedItem.id_cat_duracion"
                      :items="duraciones"
                      :label="$t('fields.capacitaciones.duration')"
                      :error-messages="errors"
                      item-text="nombre"
                      item-value="id"
                    />
                  </ValidationProvider>
                  <ValidationProvider name="capacitaciones.client" rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="editedItem.id_cliente_empresa"
                      :items="clientes_empresas"
                      :label="$t('fields.capacitaciones.client')"
                      :error-messages="errors"
                      item-text="nombre"
                      item-value="id"
                      @change="clienteEmpresaDialog"
                    />
                  </ValidationProvider>
                </v-form>
              </v-container>
            </ValidationObserver>
          </template>
        </v-list>
      </v-card>
    </v-dialog>
    <error-alert :dialog="errorDialog" />
  </div>
</template>


<script>
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { Action as capacitacionesAction } from '../../store/cruds/capacitaciones/capacitaciones/types'
import { Action as editDialogAction } from '../../store/cruds/capacitaciones/edit_dialog/types'
import { Action as editedItemAction } from '../../store/cruds/capacitaciones/edited_item/types'
import responses from "../../util/responses";
import ErrorAlert from '../ErrorAlert.vue'
const reponseUtil = new responses()

setInteractionMode('eager')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ErrorAlert
  },
  data () {
    return {
      errorDialog: false,
      loader: null,
      loading: false,
      closeDialog: false,
      editedItem: {
        id: null,
        fecha: '',
      },
      errors: null,
      fecha_menu: false,
      value: [],
      cliente_empresa_dialog: false,
      cliente_empresa: {
        nombre: null,
        rfc: null,
        registro_imss: null,
        contacto: null
      },
      fieldsRelations: {
        "fecha": "capacitaciones.date",
        "capacitadores": "capacitaciones.trainers",
        "id_cat_tipo_capacitacion": "capacitaciones.training_type",
        "id_cat_duracion": "capacitaciones.duration",
        "id_cliente_empresa": "capacitaciones.client",
      }
    }
  },
  computed: {
    capacitadores : function(){ return this.$store.getters.getCapacitadores ?? [] },
    tipos_capacitaciones : function(){ return this.$store.getters.getTiposCapacitaciones ?? [] },
    duraciones : function(){ return this.$store.getters.getDuraciones ?? [] },
    clientes_empresas : function(){ return this.$store.getters.getClientesEmpresas ?? [] },
    editedItemSt : function(){ return this.$store.getters.getCapacitacionesEditedItem ?? [] },
    isNewItemSt : function(){ return this.$store.getters.isCapacitacionesNewItem ?? [] },
    isDialogActive : {
      get: function(){ return this.$store.getters.isCapacitacionesEditDialogActive },
      set: function(val) {
        this.$store.dispatch(`${[editDialogAction.CAPACITACIONES_EDIT_DIALOG]}`, val)
      }
    },
    formattedFecha(){
      return this.editedItem.fecha ? this.$moment(this.editedItem.fecha).format('L') : ''
    }
  },
  watch: {
    editedItemSt (editedItem) {
      this.editedItem = Object.assign({}, editedItem)
    },
    loader () { 
      const l = this.loader
      this[l] = !this[l]
    },
    closeDialog: function () {
      this.$store.dispatch(`${[editDialogAction.CAPACITACIONES_EDIT_DIALOG]}`, false)
      this.$store.dispatch(`${[editedItemAction.CAPACITACIONES_IS_NEW_ITEM]}`, false)
      this.$refs.ubObs.reset()
    },
  },
  methods: {
    textFieldEnter(e){
      e.preventDefault();
      this.submit();
    },
    clienteEmpresaDialog(id){
      var cliente_empresa_id = this.clientes_empresas.findIndex(x => x.id === id)
      this.cliente_empresa = this.clientes_empresas[cliente_empresa_id]
      this.cliente_empresa_dialog = true
    },
    capacitadoresFormatted(item){
      return (item.titulo ? item.titulo + ' ' : '') +item.nombres + ' ' + item.apellidos
    },
    isNewItem(){
        if(this.isNewItemSt){
          return capacitacionesAction.CAPACITACIONES_NEW
        }
        return capacitacionesAction.CAPACITACIONES_ACT
    },
    submit() {
      // console.log('capacitadores', this.editedItem.capacitadores)
      // if(this.capacitadores) return
      this.$refs.ubObs.validate().then(res => {
        if(res){
          this.loader = 'loading'
          let data = {
            id: this.editedItem.id,
            fecha: this.editedItem.fecha,
            capacitadores: this.editedItem.capacitadores,
            id_cat_tipo_capacitacion: this.editedItem.id_cat_tipo_capacitacion,
            id_cat_duracion: this.editedItem.id_cat_duracion,
            id_cliente_empresa: this.editedItem.id_cliente_empresa
          }
          var action = this.isNewItem()
          this.$store.dispatch(`${[action]}`, data)
          .then((response, request) => {
            this.loader = null
            this.loading = false
            this.closeDialog = !this.closeDialog
            })
          .catch(err => {
            if(err && err.response && err.response.data && err.response.data.errors) {
              this.$refs.ubObs.setErrors(reponseUtil.fixResponseErrors(err.response.data.errors, this.fieldsRelations));
            } else {
              this.errorDialog = true
              this.closeDialog = !this.closeDialog
            }
            this.loader = null
            this.loading = false
          })
        }
      })
    }
  }
}
</script>